import { useLocation, useHistory } from 'react-router-dom';
import { useState, useEffect } from 'react';
import { Button } from '@mui/material';
import { useLocalStorage } from '../../services/hooks/useLocalStorage';
import { useTranslation } from 'react-i18next';

import { getWorkflowUI } from '../../services/api';
import { useInterval } from '../../services/hooks/useInterval';
import Header from '../Header';
import Loading from '../Loading';

const Processing = () => {
    const [noDipa] = useLocalStorage("noDipa");
    const [tenant] = useLocalStorage('tenant', undefined);
    const [isProcessing, setIsProcessing] = useState(true);
    const [backgroundProcessing, setBackgroundProcessing] = useState(false);
    const [split, setSplit] = useState(false);
    const [delay, setDelay] = useState(0);
    const location = useLocation();
    const history = useHistory();

    useInterval(async () => {
        if (split) {
            return;
        }
        const arn = location.state?.arn || new URLSearchParams(location.search).get("arn");
        if (!arn) {
            history.push('/', { success: false });
        }
        try {
            const response = await getWorkflowUI(arn, tenant.region);
            if (response && Object.keys(response).length === 0) {
                history.push('/', { success: false });
            }
            if (response.background || response.split) {
                setBackgroundProcessing(true);
                if (response.split) {
                    setSplit(true);
                }
            }
            if (!response.processing) {
                if (response.waitingForInput) {
                    setIsProcessing(false);
                    const params = new URLSearchParams({
                        arn: response.arn,
                        token: response.token,
                        url: response.callbackUrl,
                        workflowId: response.workflowId,
                        nodeId: response.nodeId,
                        metadata: response.metadata
                    });
                    window.location = `${response.url}?${params.toString()}`;
                } else if (response.done && !response.split) {
                    history.push('/', { success: response.success });
                }
            }
        } catch (e) {
            console.error(e);
            history.push('/', { success: false });
        }
    }, delay);

    useEffect(() => {
        if (isProcessing) {
            setDelay(2000);
        } else {
            setDelay(0);
        }
    }, [isProcessing])

    const processingDisplay = (text, subtext, buttonText) => {
        if (backgroundProcessing) {
            return (
                <Loading
                    hideIcon={true}
                    text={text}
                    subtext={subtext}
                    button={<Button variant="contained" onClick={() => history.push('/')}>{buttonText}</Button>}
                />
            );
        }
        return (
            <Loading />
        );
    }

    const [stratus] = useLocalStorage("stratus", false);
    const ns = stratus ? 'stratus' : 'scantrip';
    const { t } = useTranslation(ns);

    return (
        <>
            <Header noDipa={noDipa}/>
            <div>{processingDisplay(t('Your scanned document is being processed by ScanTrip Cloud.'), t('You may wait to input any additional information required by the workflow or return to the home screen to continue using workflows.'), t('Home'))}</div>
        </>
    )
}

export default Processing